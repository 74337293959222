import { Box, Container } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import imgBotox from "../../images/botox.webp";
import TopNav from "../../components/TopNav";
import HowItWorks from "../../components/HowItWorks";
import WhatClientsLove from "../../components/WhatClientsLove";
import DemoHero from "../../components/DemoHero";

const TitanAesthetics = () => {
  return (
    <>
      <Helmet>
        <title>Webinar Signup | Bonsai</title>
      </Helmet>
      <TopNav />
      <Box sx={{ backgroundColor: "#F1EEFF", py: { xs: 4, lg: 9 } }}>
        <Container>
          <DemoHero
            heroImg={imgBotox}
            partner="Titan Aesthetics"
            heading="Sign up for the Webinar"
            detail="Please provide the following and we will send you an invite to the Webinar or for those that can’t make the date we can schedule an alternative time."
          />
          <WhatClientsLove />
          <HowItWorks />
        </Container>
      </Box>
    </>
  );
};

export default TitanAesthetics;
