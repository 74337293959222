import { Box, Button, FormControl, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import InputMask from "react-input-mask";
import { ReactComponent as IconBankNote } from "../images/icon-arrow-right.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #31097e",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};

const ContactForm = ({ partner = null, heading = "", detail = "" }) => {
  const [fullName, setFullName] = useState("");
  const [practiceName, setPracticeName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [emr, setEmr] = useState("");
  const [bonsaiPartner, setBonsaiPartner] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [referrer, setReferrer] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    cleanForm();
    setOpenModal(false);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const queryParameters = new URLSearchParams(window.location.search);
  const leadSource = queryParameters.get("source") ?? "";

  const cleanForm = () => {
    setFullName("");
    setPracticeName("");
    setEmail("");
    setPhone("");
    setEmr("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsValidPhone(true);
    const cleanPhone = phone.replace(/[^0-9]+/g, "");
    if (cleanPhone.length < 10) {
      setIsValidPhone(false);
      return false;
    }

    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
    const emailRecipient = process.env.REACT_APP_EMAILJS_EMAIL_RECIPIENT;
    const googleScript = process.env.REACT_APP_GOOGLE_SCRIPT;

    const CURRENT_DATE = new Date();

    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("practiceName", practiceName);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("emr", emr);
    formData.append("leadSource", leadSource);
    formData.append("partner", bonsaiPartner);
    formData.append("referrer", referrer);
    formData.append("timestamp", CURRENT_DATE);

    fetch(googleScript, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.msg);
      })
      .catch((err) => console.log(err));

    emailjs
      .send(
        serviceID,
        templateID,
        {
          fullName,
          practiceName,
          email,
          phone,
          emr,
          leadSource,
          emailRecipient,
          referrer,
          partner: bonsaiPartner,
        },
        publicKey
      )
      .then(
        (result) => {
          handleOpenModal();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  useEffect(() => {
    setReferrer(document.referrer);
    if (partner) {
      setBonsaiPartner(partner);
    }
  }, [partner, bonsaiPartner]);

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        py: 4,
        px: 3.9,
        borderRadius: 2,
        width: "100%",
        maxWidth: { xs: "100%", md: 400 },
        boxShadow: "0px 12px 16px -4px #270E6714",
      }}
    >
      <Typography variant="h2" sx={{ mb: 2, color: "#31097e", fontSize: "22px", lineHeight: 1 }}>
        {heading}
      </Typography>
      <Typography sx={{ mb: 2, color: "#31097e", lineHeight: "20px" }}>{detail}</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Full Name"
          variant="outlined"
          fullWidth
          value={fullName}
          onInput={(e) => setFullName(e.target.value)}
          required
          size="small"
          margin="dense"
          className="contact-form-field"
        />
        <TextField
          label="Practice Name"
          variant="outlined"
          fullWidth
          value={practiceName}
          onInput={(e) => setPracticeName(e.target.value)}
          required
          size="small"
          margin="dense"
          className="contact-form-field"
        />
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onInput={(e) => setEmail(e.target.value)}
          type="email"
          required
          size="small"
          margin="dense"
          className="contact-form-field"
        />
        <InputMask
          mask="(999) 999-9999"
          value={phone}
          type="tel"
          onChange={handlePhoneChange}
          required
          className="contact-form-field"
        >
          <TextField
            error={!isValidPhone}
            label="Cell Phone"
            fullWidth
            margin="dense"
            size="small"
            helperText={isValidPhone ? "" : "Please enter a correct phone number."}
          />
        </InputMask>

        <TextField
          label="EMR/Practice Management System"
          variant="outlined"
          fullWidth
          value={emr}
          onInput={(e) => setEmr(e.target.value)}
          required
          size="small"
          margin="dense"
          className="contact-form-field"
        />

        <FormControl sx={{ mt: 2 }} fullWidth>
          <Button
            type="submit"
            variant="contained"
            disableElevation
            fullWidth
            className="submit-button"
            endIcon={<IconBankNote />}
          >
            Submit
          </Button>
        </FormControl>
      </form>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography variant="h3" component="p" sx={{ color: "#31097e" }}>
            Thank you for your request!
          </Typography>
          <Typography sx={{ mt: 2, color: "#31097e" }}>Our team will get in touch with you shortly.</Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default ContactForm;
