import React from "react";
import { Helmet } from "react-helmet-async";
import TopNav from "../components/TopNav";
import { Box, Container } from "@mui/material";
import DemoHero from "../components/DemoHero";
import HowItWorks from "../components/HowItWorks";
import imgHero from "../images/botox-screening.webp";
import DermatologistsTestimonials from "../components/DermatologistsTestimonials";
import { useParams } from "react-router-dom";

const Dermatology = () => {
  const params = useParams();
  return (
    <>
      <Helmet>
        <title>Demo Request | Bonsai</title>
      </Helmet>
      <TopNav showDemoLink={false} />
      <Box sx={{ backgroundColor: "#F1EEFF", py: { xs: 4, lg: 9 } }}>
        <Container>
          <DemoHero heroImg={imgHero} descriptionMaxWidth={374} heroHeight={512} partner={params.partner} />
          <DermatologistsTestimonials />
          <HowItWorks />
        </Container>
      </Box>
    </>
  );
};

export default Dermatology;
