import { createBrowserRouter } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import Error from "../pages/Error";
import HomePage from "../pages/HomePage";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsOfUse from "../pages/TermsOfUse";
import BusinessAssociateAgreement from "../pages/BusinessAssociateAgreement";
import Demo from "../pages/Demo";
import Beautiphi from "../pages/partners/Beautiphi";
import Potozkin from "../pages/partners/Potozkin";
import Dermatology from "../pages/Dermatology";
import TitanAesthetics from "../pages/partners/TitanAesthetics";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    errorElement: <Error />,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: "business-associate-agreement",
        element: <BusinessAssociateAgreement />,
      },
      { path: "privacy-policy", element: <PrivacyPolicy /> },
      { path: "terms-of-use", element: <TermsOfUse /> },
      { path: "demo", element: <Demo /> },
      { path: "dermatology", element: <Dermatology /> },
      {
        path: "partner",
        children: [
          { index: true, element: <Error /> },
          { path: "beautiphi", element: <Beautiphi /> },
          { path: "potozkin", element: <Potozkin /> },
          { path: "titan-aesthetics", element: <TitanAesthetics /> },
          { path: ":partner", element: <Dermatology /> },
        ],
      },
    ],
  },
]);

export default router;
